<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_search_group">
        <el-form :inline="true" size="min" :model="searchForm" ref="searchForm" @keyup.enter.native="getMtrlsNow()">
          <el-row>
            <el-col :md="8">
              <el-form-item label="物料编号:">
                <el-input size="small" v-model.trim="searchForm.mtrl_no" clearable placeholder="请填写物料编号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="物料名称:">
                <el-input size="small" v-model.trim="searchForm.mtrl_name" clearable placeholder="请填写物料名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="物料规格:">
                <el-input size="small" v-model.trim="searchForm.mtrl_spec" clearable placeholder="请填写物料规格"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :md="8">
              <el-form-item label="潘通色号:">
                <el-input size="small" v-model.trim="searchForm.mtrl_color" clearable placeholder="请填写潘通色号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="物料类型:">
                <el-select size="small" v-model="searchForm.mtrl_type" placeholder="请选择物料类型" clearable>
                  <el-option v-for="item in mtrlType" :key="item.id" :label="item.label" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="单据状态:">
                <el-select v-model="searchForm.status" filterable placeholder="请选择单据状态" clearable size="small">
                  <el-option v-for="item in statusList" :key="item.id" :label="item.label" :value="item.id"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :md="18">
              <el-form-item label="录入时间：">
                <div class="block vg_mr_32">
                  <el-date-picker
                    size="small"
                    v-model="searchForm.timeValue"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  >
                  </el-date-picker>
                </div>
              </el-form-item>
              <el-form-item>
                <el-button size="small" type="primary" icon="el-icon-search" @click="getMtrlsNow()" class="vg_ml_16">查询</el-button>
                <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"
                  ><i class="el-icon-refresh-right"></i> 刷新
                </el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="vd_button_group vg_mtb_16">
        <el-button type="primary" size="small" @click="addRow()" :disabled="!btn.add"><i class="el-icon-plus"></i> 新增</el-button>
        <el-button type="danger" size="small" v-if="specialDataFlag" :disabled="!btn.delete" @click="doDelete()"
          ><i class="el-icon-delete"></i> 删除</el-button
        >
        <el-button type="success" size="small" @click="dialogVisible = true">同步DYJ</el-button>
      </div>
      <el-row>
        <el-col :md="24">
          <el-table
            class="vg_cursor"
            ref="multiTable"
            :data="tableData"
            @selection-change="handleSelectionChange"
            @select="selectRows"
            @select-all="selectRows"
            @row-dblclick="dbClickJp"
            border
            v-loading="loadingFlag"
          >
            <el-table-column type="selection" width="48" v-if="specialDataFlag" align="center" />
            <el-table-column label="物料编号" prop="mtrl_no" />
            <el-table-column label="物料图片" align="center">
              <template slot-scope="scope">
                <el-image v-if="scope.row.imge_url" class="vd_elimg" :src="formatPic(scope, 's')" fit="fill"></el-image>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="物料名称" prop="mtrl_name" show-overflow-tooltip />
            <!-- <el-table-column label="供应商" prop="supp_name"  show-overflow-tooltip/> -->
            <el-table-column label="物料规格" prop="mtrl_spec" show-overflow-tooltip>
              <template slot-scope="scope">
                <span v-if="scope.row.mtrl_spec">
                  {{ scope.row.mtrl_spec }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="物料类型">
              <template slot-scope="scope">
                <span v-if="scope.row.mtrl_type >= 0">
                  {{ scope.row.mtrl_type | formatLeavType }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="物料成分" prop="mtrl_comp">
              <template slot-scope="scope">
                <span v-if="scope.row.mtrl_comp">
                  {{ scope.row.mtrl_comp }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="物料单位" prop="mtrl_unit">
              <template slot-scope="scope">
                <span v-if="scope.row.mtrl_unit">
                  {{ scope.row.mtrl_unit }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="潘通色号" prop="mtrl_color" width="130">
              <template slot-scope="scope">
                <span v-if="scope.row.mtrl_color">
                  <div class="vd_dis">
                    <div :class="{ vd_div: scope.row.colr_html }" :style="{ 'background-color': scope.row.colr_html }"></div>
                    <span>{{ scope.row.mtrl_color }}</span>
                  </div>
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="物料门幅" prop="mtrl_width">
              <template slot-scope="scope">
                <span v-if="scope.row.mtrl_width">
                  {{ scope.row.mtrl_width | formaUnitM }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="物料毛高" prop="mtrl_thick">
              <template slot-scope="scope">
                <span v-if="scope.row.mtrl_thick">
                  {{ scope.row.mtrl_thick | formaUnitH }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="物料单价" prop="mtrl_price" show-overflow-tooltip :formatter="formaUnitP"></el-table-column>
            <el-table-column label="录入人" prop="stff_name" show-overflow-tooltip :formatter="helper.personCombina" />
            <el-table-column label="录入时间" prop="create_time" :formatter="formatDate" show-overflow-tooltip />
            <el-table-column label="单据状态" :show-overflow-tooltip="true" align="center">
              <template slot-scope="scope">
                <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini"
                  >{{ helper.getStatusName(scope.row.status).name }}
                </el-tag>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="24">
          <pubPagination :totalPage="totalPage" @changePageSearch="changePageSearch" ref="pubPagination"></pubPagination>
        </el-col>
      </el-row>
      <el-dialog title="同步DYJ" :visible.sync="dialogVisible" width="70%" @opened="getDialogData">
        <DynamicUTable
          ref="dialogTable"
          :table-data="dialogTableData"
          :total-page="dialogTotalPage"
          :page-size="10"
          :need-check-box="false"
          :need-search="true"
          :columns="dialogTableProperties"
          :dbClickJp="dialogDbClick"
          @getTableData="getDialogData"
        ></DynamicUTable>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { mtrlAPI } from '@api/modules/mtrl';
import pubPagination from '@/components/common/pubPagination';
import helper from '@assets/js/helper.js';
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { cloneDeep } from 'lodash';

export default {
  name: 'MtrlList',
  components: {
    DynamicUTable,
    pubPagination
  },
  data() {
    return {
      tableData: [],
      searchForm: {
        mtrl_no: null,
        mtrl_name: '',
        mtrl_type: '',
        mtrl_spec: null,
        mtrl_color: '',
        status: null,
        timeValue: {
          startTime: null,
          endTime: null
        }
      },
      totalPage: 0,
      btn: {},
      loadingFlag: true,
      multiSelection: [],
      currentPage: 1,
      mtrlType: [
        { id: 0, label: '原面料' },
        { id: 1, label: '辅料' },
        { id: 2, label: '包材' }
      ],
      imgUpdatatime: parseInt(Math.random() * 100000000),
      specialDataFlag: false,
      statusList: [
        {
          id: 0,
          label: '草拟'
        },
        {
          id: 1,
          label: '在批'
        },
        {
          id: 2,
          label: '生效'
        }
      ],
      dialogTableData: [],
      dialogVisible: false,
      dialogTotalPage: 0,
      dialogTableProperties: [
        { label: '物料名称', prop: 'mtrl_name', itemType: 'input', input: true, sortable: false, widthAuto: true },
        { label: '物料编号', prop: 'mtrl_no', itemType: 'input', input: false, sortable: false, widthAuto: true },
        { label: '潘通色号', prop: 'mtrl_color', itemType: 'input', input: true, sortable: false, widthAuto: true },
        {
          label: '物料类型',
          prop: 'mtrl_type',
          itemType: 'select',
          options: [
            { value: 0, label: '原面料' },
            { value: 1, label: '辅料' },
            { value: 2, label: '包材' }
          ],
          input: true,
          sortable: false,
          widthAuto: true,
          formatter: row => (row === 0 ? '原面料' : row === 1 ? '辅料' : row === 2 ? '包材' : null)
        },
        { label: '物料单位', prop: 'mtrl_unit', itemType: 'input', input: false, sortable: false, widthAuto: true },
        { label: '物料毛高', prop: 'mtrl_thick', itemType: 'input', input: false, sortable: false, widthAuto: true },
        { label: '物料门幅', prop: 'mtrl_width', itemType: 'input', input: false, sortable: false, widthAuto: true },
        { label: '物料克重', prop: 'mtrl_weight', itemType: 'input', input: false, sortable: false, widthAuto: true }
      ]
    };
  },
  created() {
    this.initData();
  },
  watch: {
    $route(to, from) {
      if (from.path === '/mtrl_add' || from.path === '/mtrl_edit') {
        this.initData();
      }
    }
  },
  filters: {
    // 物料类型
    formatLeavType(row) {
      if (row === 0) {
        return '原面料';
      } else if (row === 1) {
        return '辅料';
      } else if (row === 2) {
        return '包材';
      }
    },
    formaUnitM(row) {
      return helper.haveFour(row) + '米';
    },
    formaUnitH(row) {
      return helper.reservedDigits(row) + '毫米';
    }
  },
  methods: {
    initData() {
      this.loadingFlag = true;
      this.getMtrlsList();
      if (this.$cookies.get('userInfo').acct_id === 1) {
        this.specialDataFlag = true;
      }
    },
    // 获取物料信息
    getMtrlsList() {
      const timeNewVal = this.helper.getTime(this.searchForm.timeValue);
      get(mtrlAPI.getMtrls, {
        mtrl_no: this.searchForm.mtrl_no,
        mtrl_name: this.searchForm.mtrl_name,
        mtrl_type: this.searchForm.mtrl_type,
        mtrl_spec: this.searchForm.mtrl_spec,
        mtrl_color: this.searchForm.mtrl_color,
        status: this.searchForm.status,
        start_time: timeNewVal.startTime,
        end_time: timeNewVal.endTime,
        page_no: this.currentPage
      })
        .then(res => {
          if (res.data.code === 0) {
            this.loading = false;
            this.tableData = res.data.data.list;
            this.totalPage = res.data.data.total;
            this.btn = res.data.data.btn;
            setTimeout(() => {
              this.loadingFlag = false;
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 刷新
    buttonRefresh() {
      this.searchForm = {
        mtrl_no: '',
        mtrl_name: '',
        mtrl_type: '',
        status: null,
        mtrl_color: '',
        timeValue: {
          startTime: null,
          endTime: null
        }
      };
      this.loadingFlag = true;
      this.currentPage = 1;
      this.initData();
      this.$refs.pubPagination.currentPage = 1;
    },
    // 查询方法
    getMtrlsNow() {
      this.loadingFlag = true;
      this.$refs.pubPagination.currentPage = 1;
      this.currentPage = 1;
      this.getMtrlsList();
    },
    // 状态查询
    getStatusVal(val) {
      this.searchForm.status = val;
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    // 判断是否可以删除
    selectRows(selection) {
      this.btn.delete = this.helper.isDelete(selection);
    },
    // 双击row跳转到详情编辑
    dbClickJp(row) {
      const permId = this.$route.query.perm_id;
      this.jump('/mtrl_edit', { perm_id: permId, form_id: row.mtrl_id });
    },
    // //新增
    addRow() {
      const permId = this.$route.query.perm_id;
      this.jump(`/mtrl_add?perm_id=${permId}`);
    },
    // 分页查询
    changePageSearch(val) {
      this.currentPage = val;
      this.getMtrlsList();
    },
    // 图片类型
    formatPic(scope, type) {
      if (scope.row.imge_url.indexOf('jpg') !== -1) return scope.row.imge_url;
      let updateTime = this.imgUpdatatime;
      return this.helper.picUrl(scope.row.imge_url, type, updateTime, scope.row.imge_source);
    },
    // 物料单位转换
    formaUnitP(row) {
      return this.helper.haveFour(row.mtrl_price) + '元/' + row.mtrl_unit;
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDate(row.create_time);
    },
    // 未选中提示
    doDelete() {
      if (this.multiSelection.length === 0) {
        this.$message({ type: 'warning', message: '请至少选择一条数据！' });
      } else {
        let temp = false;
        for (let i = 0; i < this.multiSelection.length; i++) {
          if (this.multiSelection[i].status === 2) {
            temp = true;
            break;
          }
        }
        if (temp) {
          this.$message({ type: 'error', message: '此单据已生效或者正在审批中,无法删除！' });
          this.$refs.multiTable.clearSelection();
        } else {
          this.mBox();
        }
      }
    },
    //物料信息list删除提示
    mBox() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const ids = [];
          this.multiSelection.map(item => {
            ids.push(item.mtrl_id);
          });
          post(mtrlAPI.deleteMtrlByIds, { mtrl_id_list: ids })
            .then(res => {
              let mg = res.data.msg;
              if (res.data.code === 0) {
                let tp = 'success';
                this.$message({ message: mg, type: tp });
                if (ids.length === this.tableData.length) {
                  if (this.currentPage > 1) {
                    this.currentPage = this.currentPage - 1;
                    this.$refs.pubPagination.currentPage = this.currentPage;
                  }
                }
                this.initData();
              } else {
                let tp = 'error';
                this.$message({ message: mg, type: tp });
                this.initData();
              }
            })
            .catch(res => {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({ message: mg, type: tp });
              this.initData();
            });
        })
        .catch(() => {
          let mg = '取消删除！';
          let tp = 'info';
          this.$message({ message: mg, type: tp });
        });
    },
    getDialogData() {
      mtrlAPI.getDyjMtrl(this.$refs.dialogTable.searchForm).then(({ data }) => {
        this.dialogTableData = data.list;
        this.dialogTotalPage = data.total;
        this.acct_no = data.acct_no;
        this.acct_id = data.acct_id;
      });
    },
    dialogDbClick(row) {
      row.dyj_mtrl_id = row.mtrl_id;
      row.dyj_mtrl_no = cloneDeep(row.mtrl_no);
      row.mtrl_price = row.mtrl_prod_price;
      row.mtrl_color = row.mtrl_color.substring(0, row.mtrl_color.length - 1);
      row.mtrl_no = row.mtrl_no.substring(0, 2);
      if (row.imge_url) {
        row.imge_url = row.imge_url.indexOf('aliyun') !== -1 ? row.imge_url : row.imge_url + '.jpg';
        row.imge_urlO = row.imge_url;
      }
      row.acct_id = this.acct_id;
      row.acct_no = this.acct_no;
      row.type = 1;
      this.dialogVisible = false;
      this.$router.push({
        name: 'MtrlAdd',
        query: { perm_id: 111, t: Date.now() },
        params: { syncData: row }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  margin-top: 5px;
  width: 60px;
  height: 60px;
}

.vd_div {
  width: 40px;
  height: 20px;
  border: none;
  margin-right: 5px;
}

.vd_dis {
  display: flex;
}
</style>
