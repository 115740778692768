<template>
  <div>
    <el-form :disabled="isShow" size="small" :model="form" @submit.native.prevent>
      <div class="sortable-column" @mouseleave="onMouseOut" v-loading="loadFlag">
        <ux-grid
          v-if="!tableStatus"
          ref="multiTable"
          @table-body-scroll="scroll"
          :show-overflow="'tooltip'"
          v-bind="$attrs"
          v-on="$listeners"
          widthResize
          column-key
          keep-source
          :size="'medium'"
          :rules="rules"
          :stripe="stripe"
          :data="tableData"
          :row-style="rowStyle"
          :cell-style="cellStyle"
          :height="tableMaxHeight"
          :show-summary="showSummary"
          :highlight-current-row="true"
          :row-class-name="rowClassName"
          :data-changes-scroll-top="false"
          :sortConfig="{ trigger: 'cell' }"
          :valid-config="{ autoPos: false }"
          :header-cell-style="headerCellStyle"
          :checkbox-config="{ checkMethod: selectable }"
          :edit-config="{ trigger: 'click', mode: 'row', autoClear: false, showIcon: false }"
          :summary-method="obj => (needAllColumnSummary ? getAllSummaries(obj) : getSummaries(obj))"
          @edit-closed="editClosed"
          @edit-actived="editActived"
          @select="tableSelect"
          @selection-change="tableSelectionChange"
          @select-all="tableSelectAll"
          @row-dblclick="dbClickJp"
          @row-click="rowClick"
          @sort-change="sortChange"
          @cell-mouse-enter="cellMouseEnter"
        >
          <ux-table-column type="checkbox" width="50" align="center" fixed="left" v-if="needCheckBox" />
          <ux-table-column type="index" width="60" align="center" fixed="left" :title="indexTitle ? indexTitle : '序号'">
            <template v-slot:header>
              <div style="position: absolute; left: 1px" v-if="needChangeColumnProperties">
                <el-button size="mini" type="primary" v-if="operationBtnShow === false" @click="operationBtnShow = true">操作</el-button>
                <el-button size="mini" type="primary" v-else @click="saveColumnsFixedStatus">保存</el-button>
              </div>
              <div
                :class="isSecondaryHeader ? 'flexHV mgt' : 'flexHV'"
                :style="needSearch ? (isSecondaryHeader ? 'height:100%' : 'height:100px') : 'height:34px'"
              >
                <div style="height: 34px" class="flexHV">序号</div>
              </div>
            </template>
          </ux-table-column>
          <template v-for="(item, index) in tableColumn">
            <!--单表头-->
            <ux-table-column
              v-if="!item.secondaryHeader"
              :key="item.prop + item.label + index"
              :resizable="true"
              :field="item.prop"
              :title="item.label"
              :sortable="item.sortable"
              :remote-sort="!!item.remoteSort"
              :fixed="item.fixed"
              :visible="item.visible"
              :align="item.align || 'center'"
              :edit-render="{ autofocus: '.el-input__inner' }"
              :width="
                item.widthAuto
                  ? 'auto'
                  : item.labelWidth
                  ? item.labelWidth
                  : item[`sum_${item.prop}`] && item[`sum_${item.prop}`].toString().length > item.label.length * 2
                  ? item[`sum_${item.prop}`].toString().length * 10
                  : item.label.length * 1.3 * 20
              "
            >
              <!--表头显示-->
              <template v-slot:header>
                <div
                  style="position: absolute; left: 0; width: 100%; display: flex"
                  @click="e => e.stopPropagation()"
                  v-if="operationBtnShow === true"
                >
                  <el-link
                    size="mini"
                    type="danger"
                    class="vg_cursor"
                    style="margin-top: 5px"
                    v-if="!columnsFixedStatus[item.prop]"
                    @click="columnsFixedStatus[item.prop] = true"
                    >冻结
                  </el-link>
                  <el-link
                    size="mini"
                    type="success"
                    class="vg_cursor"
                    style="margin-top: 5px"
                    @click="columnsFixedStatus[item.prop] = false"
                    v-else
                  >
                    解冻
                  </el-link>
                  <el-link
                    size="mini"
                    type="warning"
                    class="vg_cursor"
                    style="margin-left: auto; margin-right: 10px; margin-top: 5px"
                    @click="copyColumn(item)"
                  >
                    复制列
                  </el-link>
                </div>
                <div v-if="$scopedSlots[`header-${item.prop}`]" style="width: 100%">
                  <slot :name="`header-${item.prop}`" v-bind="searchForm" />
                </div>
                <div
                  v-else
                  @click="e => e.stopPropagation()"
                  :class="isSecondaryHeader ? 'flexHV mgt' : 'flexHV'"
                  :style="needSearch ? (isSecondaryHeader ? 'height:100%' : 'height:100px') : 'height:34px'"
                >
                  <div>
                    <div
                      style="height: 34px"
                      :class="`${item.subItem ? (item.subItem.required ? 'vg_deep_red flexHV' : 'flexHV') : 'flexHV'}`"
                    >
                      {{ item.label }}
                    </div>
                    <div v-if="needSearch">
                      <div v-if="item.itemType === 'slot'" style="width: 100%">
                        <slot :name="`header-search-${item.prop}`" v-bind="{ searchForm }" />
                      </div>
                      <el-date-picker
                        v-if="item.itemType === 'date'"
                        v-model="searchForm[item.prop]"
                        show-word-limit
                        type="date"
                        size="mini"
                        :value-format="item.valueFormat"
                        clearable
                        :disabled="item.input === false"
                        :placeholder="item.input === false ? '' : '请选择'"
                        @change="getData"
                      />
                      <el-input
                        v-if="item.itemType === 'input'"
                        size="mini"
                        :disabled="item.input === false"
                        v-model="searchForm[item.prop]"
                        :placeholder="item.input === false ? '' : '请输入'"
                        clearable
                        @clear="getData"
                        @input="val => (item.searchInput ? item.searchInput(val, searchForm, item.prop) : '')"
                        @keyup.enter.native="getData(index)"
                      />
                      <el-select
                        v-if="item.itemType === 'select'"
                        :multiple="item.multiple"
                        size="mini"
                        :multiple-limit="item.multipleLimit"
                        :name="item.name"
                        :placeholder="item.input === false ? '' : '请选择'"
                        :allow-create="item.allowCreate"
                        :disabled="item.input === false"
                        :collapse-tags="item.collapseTags"
                        :filter-method="item.filterMethod"
                        :filterable="item.filterable"
                        :loading-text="item.loadingText"
                        :no-data-text="item.noDataText"
                        :popper-class="item.popperClass"
                        :no-match-text="item.noMatchText"
                        :loading="item.loading"
                        :remote="item.remote"
                        :remote-method="item.remoteMethod"
                        v-model="searchForm[item.prop]"
                        clearable
                        @clear="getData(index)"
                        @change="() => (item.multiple ? () => {} : getData(index))"
                        @visible-change="val => (item.multiple && searchForm[item.prop].length > 0 && !val ? getData(index) : () => {})"
                        @remove-tag="typeof item.removeTag === 'function' ? item.removeTag($event) : () => {}"
                      >
                        <el-option
                          v-for="(item, index) in item.options"
                          :key="`${tableRowKey}select${item.value}${index}`"
                          :label="item.label"
                          :value="item.value"
                          :disabled="item.disabled"
                          :style="selectOptionStyle"
                        ></el-option>
                      </el-select>
                    </div>
                  </div>
                </div>
              </template>
              <!--编辑显示-->
              <template v-slot:edit="{ row, rowIndex }">
                <div
                  @click="e => e.stopPropagation()"
                  v-if="item.currColumnVisible ? item.currColumnVisible(row) : true"
                  style="width: 100%; height: 100%; display: flex"
                >
                  <div v-if="$scopedSlots[item.prop]">
                    <slot :name="item.prop" v-bind="{ row: row, $index: rowIndex }" />
                  </div>
                  <span v-else-if="$scopedSlots[`special-${item.prop}`]">
                    <slot :name="`special-${item.prop}`" v-bind="{ row: row, $index: rowIndex }" />
                  </span>
                  <div v-else-if="item.subItem">
                    <div v-if="item.subItem.type === 'input'">
                      <el-input
                        v-if="computedData && computedData[item.prop]"
                        :value="typeof computedData[item.prop] === 'function' ? computedData[item.prop](rowIndex) : computedData[item.prop]"
                        disabled
                        size="small"
                        placeholder="自动"
                      />
                      <el-input
                        v-else
                        clearable
                        @clear="row[item.prop] = null"
                        show-word-limit
                        size="small"
                        :autosize="item.subItem.autosize"
                        :clearable="item.subItem.clearable"
                        :type="item.subItem.inputType"
                        v-model="row[item.prop]"
                        :max="typeof item.subItem.max === 'function' ? item.subItem.max(row) : item.subItem.max"
                        :min="typeof item.subItem.min === 'function' ? item.subItem.min(row) : item.subItem.min"
                        :disabled="typeof item.subItem.disabled === 'function' ? item.subItem.disabled(row) : item.subItem.disabled"
                        :placeholder="item.subItem.placeholder ? item.subItem.placeholder : '请输入'"
                        :maxlength="item.subItem.maxLength"
                        @input="event => (item.subItem.input ? item.subItem.input(event, row) : () => {})"
                        @change="event => (item.subItem.change ? item.subItem.change(event, row) : () => {})"
                        @blur="event => (item.subItem.blur ? item.subItem.blur(event, row) : () => {})"
                      >
                        <template slot="append" v-if="$scopedSlots[`subItem-${item.prop}`]">
                          <slot :name="`subItem-${item.prop}`" v-bind="{ row: row, $index: rowIndex }" />
                        </template>
                      </el-input>
                    </div>
                    <div v-if="item.subItem.type === 'image'">
                      <el-image
                        lazy
                        style="height: 80px; width: 80px"
                        :preview-src-list="[typeof item.subItem.image === 'function' ? item.subItem.image(row) : item.subItem.image]"
                        :src="typeof item.subItem.thumbnail === 'function' ? item.subItem.thumbnail(row) : item.subItem.thumbnail"
                      />
                    </div>
                    <div v-if="item.subItem.type === 'uploadImage'" style="display: flex">
                      <el-image
                        lazy
                        style="height: 80px; width: 80px"
                        class="flexHV"
                        :preview-src-list="[typeof item.subItem.image === 'function' ? item.subItem.image(row) : item.subItem.image]"
                        :src="typeof item.subItem.thumbnail === 'function' ? item.subItem.thumbnail(row) : item.subItem.thumbnail"
                      >
                        <span slot="error" v-html="'暂无'"></span>
                      </el-image>
                      <el-upload
                        :ref="`uploadMultiple${rowIndex}`"
                        :action="uploadUrl"
                        :data="dataBody"
                        :show-file-list="false"
                        :on-success="res => handleAvatarSuccess(res, row)"
                        :before-upload="beforeAvatarUpload"
                      >
                        <el-link :disabled="isShow" type="primary" class="vg_cursor">点击上传</el-link>
                      </el-upload>
                      <div id="preview" @paste="e => handlePaste(e, rowIndex)" class="flexHV">
                        <el-link slot="trigger" :disabled="isShow" type="primary" class="vg_cursor">粘贴</el-link>
                      </div>
                    </div>
                    <el-date-picker
                      v-if="item.subItem.type === 'date'"
                      v-model="row[item.prop]"
                      show-word-limit
                      type="date"
                      :disabled="typeof item.subItem.disabled === 'function' ? item.subItem.disabled(row) : item.subItem.disabled"
                      @change="val => (typeof item.subItem.change === 'function' ? item.subItem.change(val, row) : '')"
                      size="small"
                      clearable
                      value-format="timestamp"
                      placeholder="请选择"
                    >
                    </el-date-picker>
                    <el-select
                      v-if="item.subItem.type === 'select'"
                      :multiple="item.subItem.multiple"
                      :clearable="item.subItem.clearable"
                      size="small"
                      :multiple-limit="item.subItem.multipleLimit"
                      :name="item.subItem.name"
                      :placeholder="item.subItem.placeholder"
                      :allow-create="item.subItem.allowCreate"
                      :disabled="typeof item.subItem.disabled === 'function' ? item.subItem.disabled(row) : item.subItem.disabled"
                      :filter-method="item.subItem.filterMethod"
                      :filterable="item.subItem.filterable ? item.subItem.filterable : true"
                      :loading-text="item.subItem.loadingText"
                      :no-data-text="item.subItem.noDataText"
                      :popper-class="item.subItem.popperClass"
                      :no-match-text="item.subItem.noMatchText"
                      :loading="item.subItem.loading"
                      :remote="item.subItem.remote"
                      :remote-method="item.subItem.remoteMethod"
                      automatic-dropdown
                      v-model="row[item.prop]"
                      clearable
                      @change="event => (item.subItem.change ? item.subItem.change(event, row, item.subItem.options) : () => {})"
                      @visible-change="typeof item.subItem.visibleChange === 'function' ? item.subItem.visibleChange($event) : () => {}"
                      @remove-tag="typeof item.subItem.removeTag === 'function' ? item.subItem.removeTag($event) : () => {}"
                    >
                      <el-option
                        v-for="(subOption, index) in item.subItem.options"
                        :key="`subSelect${subOption.value}${index}`"
                        :label="subOption.label"
                        :value="subOption.value"
                        :disabled="subOption.disabled"
                        :style="selectOptionStyle"
                      />
                    </el-select>
                    <el-select
                      v-if="item.subItem.type === 'newSelect'"
                      :multiple="item.subItem.multiple"
                      :clearable="item.subItem.clearable"
                      size="small"
                      :multiple-limit="item.subItem.multipleLimit"
                      :name="item.subItem.name"
                      :placeholder="item.subItem.placeholder"
                      :allow-create="item.subItem.allowCreate"
                      :disabled="typeof item.subItem.disabled === 'function' ? item.subItem.disabled(row) : item.subItem.disabled"
                      :filter-method="item.subItem.filterMethod"
                      :filterable="item.subItem.filterable ? item.subItem.filterable : true"
                      :loading-text="item.subItem.loadingText"
                      :no-data-text="item.subItem.noDataText"
                      :popper-class="item.subItem.popperClass"
                      :no-match-text="item.subItem.noMatchText"
                      :loading="item.subItem.loading"
                      :remote="item.subItem.remote"
                      :remote-method="item.subItem.remoteMethod"
                      v-model="row[item.prop]"
                      automatic-dropdown
                      clearable
                      @change="event => (item.subItem.change ? item.subItem.change(event, row, item.subItem.options) : () => {})"
                      @visible-change="typeof item.subItem.visibleChange === 'function' ? item.subItem.visibleChange($event) : () => {}"
                      @remove-tag="typeof item.subItem.removeTag === 'function' ? item.subItem.removeTag($event) : () => {}"
                    >
                      <el-option
                        v-for="(subOption, index) in Object.values(item.subItem.options)"
                        :key="`subSelect${subOption.value}${index}`"
                        :label="subOption.label"
                        :value="subOption.value"
                        :disabled="subOption.disabled"
                        :style="selectOptionStyle"
                      />
                    </el-select>
                    <el-input
                      :disabled="typeof item.subItem.disabled === 'function' ? item.subItem.disabled(row) : item.subItem.disabled"
                      size="small"
                      :maxlength="item.subItem.maxLength"
                      v-else-if="item.subItem.type === 'popoverInput'"
                      v-model="row[item.prop]"
                      show-word-limit
                    >
                      <template slot="append">
                        <el-popover placement="bottom" width="600" trigger="click">
                          <el-input
                            :disabled="typeof item.subItem.disabled === 'function' ? item.subItem.disabled(row) : item.subItem.disabled"
                            type="textarea"
                            :maxlength="item.subItem.maxLength"
                            :autosize="{ minRows: 5, maxRows: 10 }"
                            v-model="row[item.prop]"
                          ></el-input>
                          <span slot="reference">
                            <el-link
                              class="vg_cursor"
                              :disabled="typeof item.subItem.disabled === 'function' ? item.subItem.disabled(row) : item.subItem.disabled"
                              type="info"
                              ><i class="el-icon-more" />
                            </el-link>
                          </span>
                        </el-popover>
                      </template>
                    </el-input>
                  </div>
                  <div
                    v-else
                    :class="defaultStatusClass(item, row) + ' text'"
                    style="height: 100%; width: 100%"
                    v-html="
                      item.formatter
                        ? item.needOtherColumn
                          ? item.formatter(row)
                          : item.formatter(row[item.prop])
                        : row[item.prop] || row[item.prop] === 0
                        ? row[item.prop]
                        : '&nbsp;'
                    "
                  ></div>
                  <div
                    v-if="item.subItem && item.subItem.copyAndPaste"
                    style="display: flex; flex-direction: column; justify-content: center"
                  >
                    <el-tooltip effect="dark" content="复制" placement="right">
                      <el-link
                        class="vg_cursor"
                        :disabled="isShow"
                        :underline="false"
                        icon="el-icon-document-copy"
                        @click="
                          copyCell(
                            `${item.prop}[copyDataFlag]${row[item.prop]}[copyDataFlag]${rowIndex}[copyDataFlag]${typeof row[item.prop]}`
                          )
                        "
                      ></el-link>
                    </el-tooltip>
                    <span style="height: 9px"></span>
                    <el-tooltip effect="dark" content="粘贴" placement="right">
                      <el-link
                        class="vg_cursor"
                        :disabled="isShow"
                        :underline="false"
                        icon="el-icon-finished"
                        @click="pasteCell(item.prop, rowIndex)"
                      ></el-link>
                    </el-tooltip>
                  </div>
                </div>
              </template>
              <!--默认显示-->
              <template v-slot:default="{ row, rowIndex }">
                <div
                  v-if="item.currColumnVisible ? item.currColumnVisible(row) : true"
                  class="flexV"
                  :style="item.align ? 'justify-content:' + item.align : 'justify-content:center'"
                >
                  <!--通用插槽-->
                  <div v-if="$scopedSlots[item.prop]" style="height: 100%; width: 100%">
                    <slot :name="item.prop" v-bind="{ row: row, $index: rowIndex }" />
                  </div>
                  <div v-else-if="$scopedSlots[`default-${item.prop}`]" style="height: 100%; width: 100%">
                    <slot :name="`default-${item.prop}`" v-bind="{ row: row, $index: rowIndex }" />
                  </div>
                  <!--图片显示-->
                  <div
                    style="height: 100%; width: 100%"
                    class="flexHV"
                    v-else-if="item.subItem && (item.subItem.type === 'image' || item.subItem.type === 'uploadImage')"
                  >
                    <el-image
                      @click="
                        event => {
                          $refs.previewImg.showViewer = true;
                          event.stopPropagation();
                        }
                      "
                      ref="previewImg"
                      style="height: 80px; width: 80px"
                      class="flexHV"
                      :lazy="item.subItem.lazy"
                      :preview-src-list="[typeof item.subItem.image === 'function' ? item.subItem.image(row) : item.subItem.image]"
                      :src="typeof item.subItem.thumbnail === 'function' ? item.subItem.thumbnail(row) : item.subItem.thumbnail"
                    >
                      <span slot="error" v-html="'暂无'"></span>
                    </el-image>
                  </div>
                  <!--选择框-->
                  <div
                    style="height: 100%; width: 100%"
                    v-else-if="item.subItem && item.subItem.type === 'newSelect'"
                    :class="defaultStatusClass(item, row)"
                    v-html="item.subItem.options[row[item.prop]] ? item.subItem.options[row[item.prop]].label : '&nbsp;'"
                  />
                  <div v-else :class="defaultStatusClass(item, row) + ' text'">
                    <el-tooltip effect="dark" :content="defaultText(item, row)" placement="bottom">
                      <div style="height: 100%; width: 100%">{{ defaultText(item, row) }}</div>
                    </el-tooltip>
                  </div>
                  <div
                    v-if="item.subItem && item.subItem.copyAndPaste"
                    style="display: flex; flex-direction: column; justify-content: center"
                  >
                    <el-tooltip effect="dark" content="复制" placement="right">
                      <el-link
                        class="vg_cursor"
                        :disabled="isShow"
                        :underline="false"
                        icon="el-icon-document-copy"
                        @click="
                          copyCell(
                            `${item.prop}[copyDataFlag]${row[item.prop]}[copyDataFlag]${rowIndex}[copyDataFlag]${typeof row[item.prop]}`
                          )
                        "
                      ></el-link>
                    </el-tooltip>
                    <span style="height: 6px"></span>
                    <el-tooltip effect="dark" content="粘贴" placement="right">
                      <el-link
                        class="vg_cursor"
                        :disabled="isShow"
                        :underline="false"
                        icon="el-icon-finished"
                        @click="pasteCell(item.prop, rowIndex)"
                      ></el-link>
                    </el-tooltip>
                  </div>
                </div>
              </template>
            </ux-table-column>
            <!--多级表头-->
            <ux-table-column v-else :title="item.label" align="center">
              <ux-table-column
                v-for="(secondary, sIndex) in item.secondaryHeader"
                :key="sIndex"
                :resizable="true"
                :field="secondary.prop"
                :title="secondary.label"
                :sortable="secondary.sortable"
                :remote-sort="!!secondary.remoteSort"
                :visible="secondary.visible"
                :align="secondary.align ? secondary.align : 'center'"
                :edit-render="{ autofocus: '.el-input__inner' }"
                :width="
                  secondary.widthAuto
                    ? 'auto'
                    : secondary.labelWidth
                    ? secondary.labelWidth
                    : secondary[`sum_${secondary.prop}`] &&
                      secondary[`sum_${secondary.prop}`].toString().length > secondary.label.length * 2
                    ? secondary[`sum_${secondary.prop}`].toString().length * 10
                    : secondary.label.length * 1.3 * 20
                "
              >
                <template v-slot:header>
                  <div v-if="$scopedSlots[`header-${secondary.prop}`]" style="width: 100%">
                    <slot :name="`header-${secondary.prop}`" v-bind="searchForm" />
                  </div>
                  <div v-else @click="e => e.stopPropagation()" class="flexHV" :style="needSearch ? 'height:100px' : 'height:34px'">
                    <div>
                      <div
                        style="height: 34px"
                        :class="`${secondary.subItem ? (secondary.subsItem.required ? 'vg_deep_red flexHV' : 'flexHV') : 'flexHV'}`"
                      >
                        {{ secondary.label }}
                      </div>
                      <div v-if="needSearch">
                        <el-date-picker
                          v-if="secondary.itemType === 'date'"
                          v-model="searchForm[secondary.prop]"
                          show-word-limit
                          type="date"
                          size="mini"
                          :value-format="secondary.valueFormat"
                          clearable
                          :disabled="secondary.input === false"
                          :placeholder="secondary.input === false ? '' : '请选择'"
                          @change="getData"
                        />
                        <el-input
                          v-if="secondary.itemType === 'input'"
                          size="mini"
                          :disabled="secondary.input === false"
                          v-model="searchForm[secondary.prop]"
                          :placeholder="secondary.input === false ? '' : '请输入'"
                          clearable
                          @clear="getData"
                          @input="val => (secondary.searchInput ? secondary.searchInput(val, searchForm, secondary.prop) : '')"
                          @keyup.enter.native="getData(sIndex)"
                        />
                        <el-select
                          v-if="secondary.itemType === 'select'"
                          :multiple="secondary.multiple"
                          size="mini"
                          :multiple-limit="secondary.multipleLimit"
                          :name="secondary.name"
                          :placeholder="secondary.input === false ? '' : '请选择'"
                          :allow-create="secondary.allowCreate"
                          :disabled="secondary.input === false"
                          :collapse-tags="secondary.collapseTags"
                          :filter-method="secondary.filterMethod"
                          :filterable="secondary.filterable"
                          :loading-text="secondary.loadingText"
                          :no-data-text="secondary.noDataText"
                          :popper-class="secondary.popperClass"
                          :no-match-text="secondary.noMatchText"
                          :loading="secondary.loading"
                          :remote="secondary.remote"
                          :remote-method="secondary.remoteMethod"
                          v-model="searchForm[secondary.prop]"
                          clearable
                          @change="getData"
                          @visible-change="typeof secondary.visibleChange === 'function' ? secondary.visibleChange($event) : () => {}"
                          @remove-tag="typeof secondary.removeTag === 'function' ? secondary.removeTag($event) : () => {}"
                        >
                          <el-option
                            v-for="secondary in secondary.options"
                            :key="`${tableRowKey}select${secondary.value}`"
                            :label="secondary.label"
                            :value="secondary.value"
                            :disabled="secondary.disabled"
                            :style="selectOptionStyle"
                          ></el-option>
                        </el-select>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-slot:edit="{ row, rowIndex }">
                  <div
                    @click="e => e.stopPropagation()"
                    v-if="secondary.currColumnVisible ? secondary.currColumnVisible(row) : true"
                    style="width: 100%; height: 100%"
                  >
                    <div v-if="$scopedSlots[secondary.prop]">
                      <slot :name="secondary.prop" v-bind="{ row: row, $index: rowIndex }" />
                    </div>
                    <span v-else-if="$scopedSlots[`special-${secondary.prop}`]">
                      <slot :name="`special-${secondary.prop}`" v-bind="{ row: row, $index: rowIndex }" />
                    </span>
                    <div v-else-if="secondary.subItem">
                      <div v-if="secondary.subsItem.type === 'input'">
                        <el-input
                          v-if="computedData && computedData[secondary.prop]"
                          :value="
                            typeof computedData[secondary.prop] === 'function'
                              ? computedData[secondary.prop](rowIndex)
                              : computedData[secondary.prop]
                          "
                          disabled
                          size="small"
                          placeholder="自动"
                        />
                        <el-input
                          v-else
                          clearable
                          @clear="row[secondary.prop] = null"
                          show-word-limit
                          size="small"
                          :autosize="secondary.subsItem.autosize"
                          :clearable="secondary.subsItem.clearable"
                          :type="secondary.subsItem.inputType"
                          v-model="row[secondary.prop]"
                          :max="typeof secondary.subsItem.max === 'function' ? secondary.subsItem.max(row) : secondary.subsItem.max"
                          :min="typeof secondary.subsItem.min === 'function' ? secondary.subsItem.min(row) : secondary.subsItem.min"
                          :disabled="
                            typeof secondary.subsItem.disabled === 'function'
                              ? secondary.subsItem.disabled(row)
                              : secondary.subsItem.disabled
                          "
                          :placeholder="secondary.subsItem.placeholder ? secondary.subsItem.placeholder : '请输入'"
                          :maxlength="secondary.subsItem.maxLength"
                          @input="event => (secondary.subsItem.input ? secondary.subsItem.input(event, row) : () => {})"
                          @change="event => (secondary.subsItem.change ? secondary.subsItem.change(event, row) : () => {})"
                          @blur="event => (secondary.subsItem.blur ? secondary.subsItem.blur(event, row) : () => {})"
                        >
                          <template slot="append" v-if="$scopedSlots[`subItem-${secondary.prop}`]">
                            <slot :name="`subItem-${secondary.prop}`" v-bind="{ row: row, $index: rowIndex }" />
                          </template>
                        </el-input>
                      </div>
                      <div v-if="secondary.subsItem.type === 'image'">
                        <el-image
                          lazy
                          style="height: 80px; width: 80px"
                          :preview-src-list="[
                            typeof secondary.subsItem.image === 'function' ? secondary.subsItem.image(row) : secondary.subsItem.image
                          ]"
                          :src="
                            typeof secondary.subsItem.thumbnail === 'function'
                              ? secondary.subsItem.thumbnail(row)
                              : secondary.subsItem.thumbnail
                          "
                        />
                      </div>
                      <div v-if="secondary.subsItem.type === 'uploadImage'" style="display: flex">
                        <el-image
                          lazy
                          style="height: 80px; width: 80px"
                          class="flexHV"
                          :preview-src-list="[
                            typeof secondary.subsItem.image === 'function' ? secondary.subsItem.image(row) : secondary.subsItem.image
                          ]"
                          :src="
                            typeof secondary.subsItem.thumbnail === 'function'
                              ? secondary.subsItem.thumbnail(row)
                              : secondary.subsItem.thumbnail
                          "
                        >
                          <span slot="error" v-html="'暂无'"></span>
                        </el-image>
                        <el-upload
                          :ref="`uploadMultiple${rowIndex}`"
                          :action="uploadUrl"
                          :data="dataBody"
                          :show-file-list="false"
                          :on-success="res => handleAvatarSuccess(res, row)"
                          :before-upload="beforeAvatarUpload"
                        >
                          <el-link :disabled="isShow" type="primary" class="vg_cursor">点击上传</el-link>
                        </el-upload>
                        <div id="preview" @paste="e => handlePaste(e, rowIndex)" class="flexHV">
                          <el-link slot="trigger" :disabled="isShow" type="primary" class="vg_cursor">粘贴</el-link>
                        </div>
                      </div>
                      <el-date-picker
                        v-if="secondary.subsItem.type === 'date'"
                        v-model="row[secondary.prop]"
                        show-word-limit
                        type="date"
                        :disabled="
                          typeof secondary.subsItem.disabled === 'function' ? secondary.subsItem.disabled(row) : secondary.subsItem.disabled
                        "
                        @change="
                          event =>
                            secondary.subsItem.change ? secondary.subsItem.change(event, row, secondary.subsItem.options) : () => {}
                        "
                        size="small"
                        clearable
                        value-format="timestamp"
                        placeholder="请选择"
                      >
                      </el-date-picker>
                      <el-select
                        v-if="secondary.subsItem.type === 'select'"
                        :multiple="secondary.subsItem.multiple"
                        :clearable="secondary.subsItem.clearable"
                        size="small"
                        :multiple-limit="secondary.subsItem.multipleLimit"
                        :name="secondary.subsItem.name"
                        :placeholder="secondary.subsItem.placeholder"
                        :allow-create="secondary.subsItem.allowCreate"
                        :disabled="
                          typeof secondary.subsItem.disabled === 'function' ? secondary.subsItem.disabled(row) : secondary.subsItem.disabled
                        "
                        :filter-method="secondary.subsItem.filterMethod"
                        :filterable="secondary.subsItem.filterable ? secondary.subsItem.filterable : true"
                        :loading-text="secondary.subsItem.loadingText"
                        :no-data-text="secondary.subsItem.noDataText"
                        :popper-class="secondary.subsItem.popperClass"
                        :no-match-text="secondary.subsItem.noMatchText"
                        :loading="secondary.subsItem.loading"
                        :remote="secondary.subsItem.remote"
                        :remote-method="secondary.subsItem.remoteMethod"
                        automatic-dropdown
                        v-model="row[secondary.prop]"
                        clearable
                        @change="
                          event =>
                            secondary.subsItem.change ? secondary.subsItem.change(event, row, secondary.subsItem.options) : () => {}
                        "
                        @visible-change="
                          typeof secondary.subsItem.visibleChange === 'function' ? secondary.subsItem.visibleChange($event) : () => {}
                        "
                        @remove-tag="typeof secondary.subsItem.removeTag === 'function' ? secondary.subsItem.removeTag($event) : () => {}"
                      >
                        <el-option
                          v-for="(subOption, subOptionIndex) in secondary.subsItem.options"
                          :key="`subSelect${subOption.value}${subOptionIndex}`"
                          :label="subOption.label"
                          :value="subOption.value"
                          :disabled="subOption.disabled"
                          :style="selectOptionStyle"
                        />
                      </el-select>
                      <el-select
                        v-if="secondary.subsItem.type === 'newSelect'"
                        :multiple="secondary.subsItem.multiple"
                        :clearable="secondary.subsItem.clearable"
                        size="small"
                        :multiple-limit="secondary.subsItem.multipleLimit"
                        :name="secondary.subsItem.name"
                        :placeholder="secondary.subsItem.placeholder"
                        :allow-create="secondary.subsItem.allowCreate"
                        :disabled="
                          typeof secondary.subsItem.disabled === 'function' ? secondary.subsItem.disabled(row) : secondary.subsItem.disabled
                        "
                        :filter-method="secondary.subsItem.filterMethod"
                        :filterable="secondary.subsItem.filterable ? secondary.subsItem.filterable : true"
                        :loading-text="secondary.subsItem.loadingText"
                        :no-data-text="secondary.subsItem.noDataText"
                        :popper-class="secondary.subsItem.popperClass"
                        :no-match-text="secondary.subsItem.noMatchText"
                        :loading="secondary.subsItem.loading"
                        :remote="secondary.subsItem.remote"
                        :remote-method="secondary.subsItem.remoteMethod"
                        v-model="row[secondary.prop]"
                        automatic-dropdown
                        clearable
                        @change="
                          event =>
                            secondary.subsItem.change ? secondary.subsItem.change(event, row, secondary.subsItem.options) : () => {}
                        "
                        @visible-change="
                          typeof secondary.subsItem.visibleChange === 'function' ? secondary.subsItem.visibleChange($event) : () => {}
                        "
                        @remove-tag="typeof secondary.subsItem.removeTag === 'function' ? secondary.subsItem.removeTag($event) : () => {}"
                      >
                        <el-option
                          v-for="(subOption, index) in Object.values(secondary.subsItem.options)"
                          :key="`subSelect${subOption.value}${index}`"
                          :label="subOption.label"
                          :value="subOption.value"
                          :disabled="subOption.disabled"
                          :style="selectOptionStyle"
                        />
                      </el-select>
                      <el-input
                        :disabled="
                          typeof secondary.subsItem.disabled === 'function' ? secondary.subsItem.disabled(row) : secondary.subsItem.disabled
                        "
                        size="small"
                        :maxlength="secondary.subsItem.maxLength"
                        v-else-if="secondary.subsItem.type === 'popoverInput'"
                        v-model="row[secondary.prop]"
                        show-word-limit
                      >
                        <template slot="append">
                          <el-popover placement="bottom" width="600" trigger="click">
                            <el-input
                              :disabled="
                                typeof secondary.subsItem.disabled === 'function'
                                  ? secondary.subsItem.disabled(row)
                                  : secondary.subsItem.disabled
                              "
                              type="textarea"
                              :maxlength="secondary.subsItem.maxLength"
                              :autosize="{ minRows: 5, maxRows: 10 }"
                              v-model="row[secondary.prop]"
                            ></el-input>
                            <span slot="reference">
                              <el-link
                                class="vg_cursor"
                                :disabled="
                                  typeof secondary.subsItem.disabled === 'function'
                                    ? secondary.subsItem.disabled(row)
                                    : secondary.subsItem.disabled
                                "
                                type="info"
                                ><i class="el-icon-more"></i
                              ></el-link>
                            </span>
                          </el-popover>
                        </template>
                      </el-input>
                    </div>
                    <span
                      v-else
                      v-html="
                        secondary.formatter
                          ? secondary.needOtherColumn
                            ? secondary.formatter(row)
                            : secondary.formatter(row[secondary.prop])
                          : row[secondary.prop]
                          ? row[secondary.prop]
                          : '&nbsp;'
                      "
                    >
                    </span>
                  </div>
                </template>
                <template v-slot:default="{ row, rowIndex }">
                  <div
                    v-if="secondary.currColumnVisible ? secondary.currColumnVisible(row) : true"
                    class="flexV"
                    :style="secondary.align ? 'justify-content:' + secondary.align : 'justify-content:center'"
                  >
                    <div v-if="$scopedSlots[secondary.prop]" style="height: 100%; width: 100%">
                      <slot :name="secondary.prop" v-bind="{ row: row, $index: rowIndex }" />
                    </div>
                    <div v-else-if="$scopedSlots[`default-${secondary.prop}`]" style="height: 100%; width: 100%">
                      <slot :name="`default-${secondary.prop}`" v-bind="{ row: row, $index: rowIndex }" />
                    </div>
                    <div
                      style="height: 100%; width: 100%"
                      class="flexHV"
                      v-else-if="secondary.subItem && (secondary.subsItem.type === 'image' || secondary.subsItem.type === 'uploadImage')"
                    >
                      <el-image
                        @click="
                          event => {
                            $refs.previewImg.showViewer = true;
                            event.stopPropagation();
                          }
                        "
                        ref="previewImg"
                        style="height: 80px; width: 80px"
                        class="flexHV"
                        :lazy="secondary.subsItem.lazy"
                        :preview-src-list="[
                          typeof secondary.subsItem.image === 'function' ? secondary.subsItem.image(row) : secondary.subsItem.image
                        ]"
                        :src="
                          typeof secondary.subsItem.thumbnail === 'function'
                            ? secondary.subsItem.thumbnail(row)
                            : secondary.subsItem.thumbnail
                        "
                      >
                        <span slot="error" v-html="'暂无'"></span>
                      </el-image>
                    </div>
                    <div
                      style="height: 100%; width: 100%"
                      v-else-if="secondary.subItem && secondary.subsItem.type === 'newSelect'"
                      :class="defaultStatusClass(secondary, row)"
                      v-html="
                        secondary.subsItem.options[row[secondary.prop]] ? secondary.subsItem.options[row[secondary.prop]].label : '&nbsp;'
                      "
                    ></div>
                    <div
                      v-else
                      :class="defaultStatusClass(secondary, row)"
                      style="height: 100%; width: 100%"
                      v-html="
                        secondary.formatter
                          ? secondary.needOtherColumn
                            ? secondary.formatter(row)
                            : secondary.formatter(row[secondary.prop])
                          : row[secondary.prop] || row[secondary.prop] === 0
                          ? row[secondary.prop]
                          : '&nbsp;'
                      "
                    ></div>
                  </div>
                </template>
              </ux-table-column>
            </ux-table-column>
          </template>
        </ux-grid>
      </div>
      <el-pagination
        class="tablePagination"
        v-if="needPagination"
        background
        @size-change="sizeChangeHandle"
        @current-change="currentChangeHandle"
        :current-page="currentPage"
        :total="totalPage"
        :page-size="pageSize"
        layout="total, prev, pager, next"
        ref="pubPagination"
      >
      </el-pagination>
    </el-form>
  </div>
</template>

<script>
import Sortable from 'sortablejs/modular/sortable.complete.esm.js';
import { BigNumber } from 'bignumber.js';
import textEnlargement from '@/views/component/textEnlargement.vue';
import imgPopover from '@/views/component/imgPopover.vue';
import helper from '@assets/js/helper';
import { imgeAPI } from '@api/modules/imge';
import { cloneDeep } from 'lodash';
import Vue from 'vue';

export default {
  name: 'DynamicUTable',
  components: { imgPopover, textEnlargement, Sortable },
  props: {
    form: { type: Object, default: () => {} },
    isShow: { type: Boolean, default: false },
    selectable: { type: Function, default: () => true },
    computedData: { type: Object, default: () => {} },
    otherComputedData: { type: Object, default: () => {} },
    columns: { type: Array, default: () => [] },
    stripe: { type: Boolean, default: true },
    showSummary: { type: Boolean, default: false },
    needAllColumnSummary: { type: Boolean, default: false },
    skipSummaryIndex: { type: Array, default: () => [] },
    needClosedUpdate: { type: Boolean, default: false },
    needAllColumnSummaryToFixed: { type: Number, default: 4 },
    summariesColumns: { type: Array, default: () => [] },
    needSummaryValues: { type: Array, default: () => [] },
    pagination: { type: Object, default: () => ({}) },
    tableData: { type: Array, default: () => [] },
    loadFlag: { type: Boolean, default: false },
    needPagination: { type: Boolean, default: true },
    needCheckBox: { type: Boolean, default: true },
    needSort: { type: Boolean, default: false },
    needSummation: { type: Boolean, default: false },
    needSearch: { type: Boolean, default: false },
    needFixedHeight: { type: Boolean, default: false },
    needChangeColumnProperties: { type: Boolean, default: false },
    rowMove: { type: Boolean, default: false },
    columnMove: { type: Boolean, default: true },
    dbClickJp: { type: Function, default: () => {} },
    rowClick: { type: Function, default: () => {} },
    rowClassName: { type: Function, default: () => {} },
    rowKey: { type: Function || String, default: null },
    rowStyle: { type: Function, default: ({ row, rowIndex }) => Vue.set(row, 'index', rowIndex) },
    tableSelect: { type: Function, default: () => null },
    tableSelectionChange: { type: Function, default: () => null },
    tableSelectAll: { type: Function, default: () => null },
    totalPage: { type: Number, default: 0 },
    pageSize: { type: Number, default: 20 },
    maxHeight: { type: Number, default: 400 },
    getTableData: { type: Function, default: () => null },
    editClosed: { type: Function, default: () => {} },
    editActived: { type: Function, default: () => {} },
    cellMouseEnter: { type: Function, default: () => {} },
    autoHeight: { type: Boolean, default: false },
    selectOptionStyle: String,
    tableRowKey: String,
    cellStyle: {
      type: Function || Object,
      default: () => {
        return {
          'font-size': '14px'
        };
      }
    },
    indexTitle: String
  },
  watch: {
    tableData(newValue, oldValue) {
      if (oldValue && oldValue.length === 0) return;
      this.clearSelection();
    }
  },
  computed: {
    defaultStatusClass(item, row) {
      return (item, row) => {
        if (item.subItem) {
          let disabled = typeof item.subItem.disabled === 'function' ? item.subItem.disabled(row) : item.subItem.disabled;
          return disabled || this.isShow ? 'my-input-sc my-input-sc-disabled' : 'my-input-sc my-input-sc-show';
        }
      };
    },
    tableColumn() {
      return this.columns;
    },
    rules() {
      if (this.isShow) return {};
      let temp = {};
      this.columns.forEach(({ prop, label, subItem }) => {
        let propRule = [];
        propRule.push({ required: subItem?.required ?? false, message: `${label}必填!` });
        temp[prop] = propRule;
      });
      return temp;
    }
  },
  data() {
    return {
      searchForm: {
        page_no: 1
      },
      tableKey: '',
      tableMaxHeight: 0,
      currentPage: 1,
      originalObject: {
        destroy_flag: null
      },
      uploadUrl: helper.modePrepend(imgeAPI.uploadImage),
      dataBody: {
        file: /\.(png|jpg|gif|jpeg)$/,
        cut: true,
        keep: false,
        x: 400,
        y: 400
      },
      observer: new IntersectionObserver(this.handleIntersection, {
        root: null,
        rootMargin: '0px',
        threshold: 0.5 // 交叉区域比例
      }),
      scrollTop: 0,
      scrollLeft: 0,
      isSecondaryHeader: false,
      popoverInputObj: {},
      columnsFixedStatus: {},
      operationBtnShow: false,
      tableStatus: false
    };
  },
  created() {
    this.initSearchForm();
  },
  async mounted() {
    await this.addObject();
    // await this.rowDrop();
    await this.columnDrop();
    setTimeout(() => {
      this.getTableMaxHeight();
      this.observer.observe(this.$refs.multiTable.$el);
    });
  },
  beforeDestroy() {
    this.observer.disconnect();
    console.log('observer disconnected!');
  },
  methods: {
    cellMouseLeave() {
      if (this.needClosedUpdate) this.$refs.multiTable.updateData();
    },
    initSearchForm() {
      this.columns.forEach(({ prop }) => {
        if (this.needSearch) this.$set(this.searchForm, prop, null);
        this.$set(this.columnsFixedStatus, prop, false);
      });
    },
    handleIntersection(entries) {
      entries.forEach(({ isIntersecting }) => {
        if (isIntersecting) this.$refs.multiTable.pagingScrollTopLeft(this.scrollTop, this.scrollLeft - 10);
      });
    },
    getTableMaxHeight() {
      if (this.autoHeight) {
        this.tableMaxHeight = null;
        return;
      }
      if (this.needFixedHeight || this.$route.path.indexOf('list') !== -1) {
        let { y } = this.$refs.multiTable.$el.getBoundingClientRect();
        // 40 = padding | 52 = pagination height
        this.tableMaxHeight = window.innerHeight - y - 40 - 52;
        return;
      }
      this.tableMaxHeight = this.maxHeight + (this.showSummary ? 44 : 0);
    },
    addObject() {
      this.columns.forEach(({ prop }) => (this.originalObject[prop] = ''));
    },
    reload(data) {
      this.$refs.multiTable?.reloadData(data ?? this.tableData);
    },
    rowDrop() {
      if (this.rowMove === false) return;
      this.$nextTick(() => {
        let plxTable = this.$refs.multiTable;
        this.sortable = Sortable.create(plxTable.$el.querySelector('.body--wrapper .elx-table--body tbody'), {
          handle: '.elx-body--row',
          delay: 300,
          onEnd: ({ item, newIndex, oldIndex }) => {
            let currRow = this.tableData.splice(oldIndex, 1)[0];
            this.tableData.splice(newIndex, 0, currRow);
          }
        });
      });
    },
    columnDrop() {
      if (this.columnMove === false) return;
      this.$nextTick(() => {
        let plxTable = this.$refs.multiTable;
        let plxFirstRow = plxTable.$el.querySelector('.elx-table--header .elx-header--row:nth-child(1)');
        this.sortable = Sortable.create(plxFirstRow, {
          handle: '.elx-header--column:not(.col--fixed)',
          ghostClass: 'dragColbg',
          chosenClass: 'dragColbg',
          animation: 300,
          invertSwap: false,
          delay: 300,
          onEnd: ({ item, newIndex, oldIndex }) => {
            if (newIndex === oldIndex) return;
            let { collectColumn, tableColumn } = plxTable.getTableColumn();
            let oldColumnIndex = collectColumn.findIndex(({ title }) => title === tableColumn[oldIndex].title);
            let newColumnIndex = collectColumn.findIndex(({ title }) => title === tableColumn[newIndex].title);
            let targetThElem = item;
            let wrapperElem = targetThElem.parentNode;
            let newColumn = collectColumn[newIndex];
            if (newColumn.fixed) {
              if (newIndex > oldIndex) {
                wrapperElem.insertBefore(targetThElem, wrapperElem.children[oldIndex]);
              } else {
                wrapperElem.insertBefore(wrapperElem.children[oldIndex], targetThElem);
              }
              return;
            }
            let currRow = collectColumn.splice(oldColumnIndex, 1)[0];
            collectColumn.splice(newColumnIndex, 0, currRow);
            plxTable.loadColumn(cloneDeep(collectColumn));
          }
        });
      });
    },
    sortChange({ order, prop }) {
      this.$emit('sortChange');
      if (!order) {
        this.searchForm.column = '';
        this.searchForm.order = '';
        return;
      }
      this.searchForm.column = prop;
      this.searchForm.order = order;
      this.getData();
    },
    getData(index) {
      if (this.needPagination) {
        this.currentPage = 1;
        this.searchForm.page_no = 1;
      }
      this.$emit('getTableData', index);
    },
    resetFields() {
      if (this.needPagination) {
        this.currentPage = 1;
        this.searchForm = { page_no: 1 };
        this.$refs.multiTable.clearSort();
        return;
      }
      this.$refs.multiTable.clearSort();
      this.searchForm = {};
    },
    doLayout() {
      this.$refs.multiTable.doLayout();
    },
    updateData() {
      this.$refs.multiTable?.updateData();
    },
    cellClick(row, column, cell, event) {
      event.currentTarget.cancelBubble = true;
      navigator.clipboard.writeText(event.target.innerText);
    },
    sizeChangeHandle() {},
    currentChangeHandle(val) {
      this.searchForm.page_no = val;
      this.currentPage = val;
      this.$emit('getTableData');
      this.$nextTick(() => {
        setTimeout(() => (this.scrollTop = 0));
      });
    },
    clearSelection() {
      this.$refs.multiTable?.clearSelection();
    },
    getAllSummaries({ columns, data }) {
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        if (this.skipSummaryIndex.includes(index)) {
          sums[index] = '';
          return;
        }
        let values = data.map(item => Number(item[column.property]));
        let initValue = new BigNumber(0);
        sums[index] = values
          .reduce((x, item) => {
            if (!item) return x;
            return x.plus(item);
          }, initValue)
          .toNumber();
        if (Number(sums[index]) === 0) sums[index] = '';
      });
      return [sums];
    },
    getSummaries({ columns, data }) {
      console.time('myFunction');
      const sums = [];
      const needEmitIndexArray = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        if (this.needSummaryValues.includes(column.property)) needEmitIndexArray.push(index);
        let values = data.map(item => Number(item[column.property]));
        if (this.summariesColumns.includes(column.property)) {
          let initValue = new BigNumber(0);
          sums[index] = values
            .reduce((x, item) => {
              return x.plus(item);
            }, initValue)
            .toFixed(this.needAllColumnSummaryToFixed);
        }
      });
      this.needSummaryValues.forEach((item, index) => {
        this.$emit(`getSummary_${item}`, sums[needEmitIndexArray[index]]);
      });
      console.timeEnd('myFunction');
      return [sums];
    },
    scroll({ scrollTop, scrollLeft }) {
      this.scrollTop = scrollTop;
      this.scrollLeft = scrollLeft;
    },
    async fullValidateMsg() {
      let masArr = [];
      let arr = await this.$refs.multiTable.fullValidate();
      Object.values(arr ?? []).forEach(errList => {
        errList.forEach(params => masArr.push(params.column.title));
      });
      if (masArr.length === 0) return '';
      return [...new Set(masArr)];
    },
    handleAvatarSuccess(res, row) {
      if (res.code === 0) {
        this.$set(row, 'imge_id', res.data.imge_id);
        this.$set(row, 'imge_url', res.data.imge_url);
      }
    },
    beforeAvatarUpload(res) {
      const isLt5M = res.size / 1024 / 1024 < 3;
      if (!isLt5M) {
        this.$message.error('图片大小不得超过 3MB!');
        return false;
      }
    },
    handlePaste(event, index) {
      if (!this.isShow) {
        const items = (event.clipboardData || window.clipboardData).items;
        if (!items || items.length === 0) return this.$message.error('当前浏览器不支持本地');
        if (items[0].type.indexOf('image') === -1) return this.$message.error('粘贴内容非图片');
        let file = items[0].getAsFile();
        this.$refs[`uploadMultiple${index}`][0].handleStart(file); // 将粘贴过来的图片加入预上传队列
        this.$refs[`uploadMultiple${index}`][0].submit(); // 提交图片上传队列
      } else {
        this.$message.warning('请先编辑');
      }
    },
    headerCellStyle({ columnIndex, column }) {
      if (this.columns[columnIndex]?.secondaryHeader) {
        this.isSecondaryHeader = true;
        return 'background-color: #f5f7fa;';
      } else {
        return 'background-color:#f5f7fa;';
      }
    },
    defaultText(item, row) {
      return (
        (item.formatter
          ? item.needOtherColumn
            ? item.formatter(row)
            : item.formatter(row[item.prop])
          : row[item.prop] || row[item.prop] === 0
          ? row[item.prop]
          : '') + ''
      );
    },
    onMouseOut() {
      this.updateData();
    },
    saveColumnsFixedStatus() {
      for (const key in this.columnsFixedStatus) {
        let status = this.columnsFixedStatus[key];
        let find = this.tableColumn.find(({ prop }) => prop === key);
        if (find) find.fixed = status ? 'left' : null;
      }
      this.operationBtnShow = false;
      this.reloadTable();
    },
    copyColumn({ prop, itemType, formatter }) {
      let str = '';
      this.tableData.forEach(item => {
        if (itemType === 'date') str += item[prop] ? formatter(item[prop]) + '\n' : '\n';
        else str += item[prop] + '\n';
      });
      navigator.clipboard.writeText(str);
    },
    reloadTable() {
      this.tableStatus = true;
      setTimeout(() => (this.tableStatus = false), 1000);
    },
    refreshColumn() {
      setTimeout(() => {
        this.$refs.multiTable.refreshColumn();
      });
    },
    copyCell(text) {
      navigator.clipboard.writeText(text);
    },
    pasteCell(column, index) {
      navigator.clipboard
        .readText()
        .then(text => {
          let textSplit = text.split('[copyDataFlag]');
          if (textSplit.length > 1 && textSplit[0] === column) {
            if (Number(textSplit[2]) === index) return;
            let rangeIndexArr;
            if (Number(textSplit[2]) > index) rangeIndexArr = [index, Number(textSplit[2])];
            else rangeIndexArr = [Number(textSplit[2]), index];
            this.changeData({ rangeIndexArr: rangeIndexArr, textSplit: textSplit, column: column });
          }
        })
        .catch(err => {
          console.error('无法读取剪贴板: ', err);
        });
    },
    changeData(data) {
      this.tableData.forEach((item, tIndex) => {
        if (tIndex >= data.rangeIndexArr[0] && tIndex <= data.rangeIndexArr[1]) {
          if (data.textSplit[3] === 'number') {
            this.$set(item, data.column, Number(data.textSplit[1]));
          } else {
            this.$set(item, data.column, data.textSplit[1]);
          }
        }
      });
    }
  }
};
</script>

<style scoped>
::v-deep .el-popover {
  top: 40px;
  left: -300px;
}
.mgt {
  margin-top: 44px;
}
.tablePagination {
  text-align: right;
  margin-top: 20px;
}
::v-deep .elx-header--column .col--group {
  height: 34px !important;
}
::v-deep .elx-table .elx-body--column.col--ellipsis > .elx-cell {
  max-height: 200px;
}
::v-deep .elx-table.size--medium .elx-header--column.col--ellipsis > .elx-cell {
  max-height: 200px;
}
::v-deep .elx-cell--title {
  width: 100%;
}
::v-deep .el-input .el-input--small .el-input--suffix {
  padding-right: 65px;
}
::v-deep .el-input textarea {
  padding-bottom: 13px;
}
::v-deep .el-input .el-input__count {
  height: 12px;
  line-height: 12px;
}
::v-deep .el-upload {
  display: inline;
  text-align: center;
  cursor: pointer;
  outline: 0;
}
::v-deep .el-upload-dragger {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 80px;
  background-color: #e6f7ff;
  border: 0;
}

::v-deep .upload-demo {
  display: inline;
}
::v-deep .el-upload--text {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f7f5f500;
  border: 0;
  width: 80px;
  height: 80px;
}
.summation {
  height: 20px;
  text-align: right;
  padding-right: 10px;
}

.summationBorderBottom {
  border-bottom: 1px solid #494a4d;
}

.daCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 34px;
}

.searchPagination {
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
}
</style>
<style>
.changeColor {
  background: #f8e9db !important;
}
.dragColbg {
  background-color: #8d8d8d !important;
  color: white !important;
}
.sortable-column .elx-header--row .elx-header--column.col--fixed {
  cursor: no-drop !important;
}
.my-input-sc {
  display: inline-block;
  line-height: 30px;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  font-size: inherit;
  outline: none;
  padding: 0 !important;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  height: 32px !important;
}
.my-input-sc-disabled {
  background-color: #f3f5f8;
}
.my-input-sc-show {
  background-color: #ffffff;
}
.headerRowClass {
  height: 100px !important;
  max-height: 100px !important;
  background-color: #f5f7fa !important;
}
.headerRowClassNotSearch {
  height: 48px !important;
  max-height: 48px !important;
  background-color: #f5f7fa !important;
}
.elx-table--tooltip-wrapper.elx-table--valid-error {
  display: none !important;
}
.el-input .el-input__count .el-input__count-inner {
  background: #fff0;
}
.text {
  overflow: hidden;
  text-overflow: clip;
  white-space: nowrap;
}
.elx-cell--valid {
  display: none !important;
}
</style>
