<template>
  <div>
    <el-input type="textarea"
    :disabled="disabledFlag"
    :autosize="{ minRows: 5, maxRows: 10 }"
    v-model="textEnlargementString.str"
    clearable
    @input="inputChange"
    placeholder="暂无"></el-input>
  </div>
</template>

<script>
export default {
  name:'textEnlargement',
  props:{
    textEnlargementString:{
      type:Object,
      required:true
    },
    disabledFlag:{
      type:Boolean,
      required:true
    }
  },
  data(){
    return{
      publicString:''
    }
  },
  methods:{
    inputChange(){
      this.$emit('textEnlargementChange',this.textEnlargementString.str)
    },
  }

}
</script>

<style>

</style>